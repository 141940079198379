<template>
  <footer class="bg-white border-t border-gray-100">
    <div
      class="max-w-7xl mx-auto -mt-10 sm:-mt-0 py-8 md:py-4 px-4 sm:px-6 lg:px-8 md:flex md:items-center md:justify-between"
    >
      <div class="flex justify-center space-x-6 md:order-2 pt-6 sm:pt-0">
        <div class="p-2 flex-shrink-0">
          <img
            class="hidden sm:block h-16"
            src="../assets/pfr_information.jpg"
            alt="Plansza informacyjna PFR poziom"
          />
          <img
            class="block sm:hidden h-16"
            src="../assets/pfr_information_sm.jpg"
            alt="Plansza informacyjna PFR pion środkowa"
          />
        </div>
      </div>
      <div class="mt-4 md:mt-0 md:order-1">
        <p class="text-center text-base text-gray-400">
          &copy; 2021 TOMDAR, Sp.j. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
