<template>
  <main class="lg:relative">
    <div
      class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left"
    >
      <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
        <h1
          class="text-4xl tracking-tight font-extrabold text-tomdar-blue-100 sm:text-6xl md:text-7xl lg:text-6xl xl:text-7xl"
        >
          <span class="block">TOMDAR</span>
          {{ " " }}
          <span
            class="block my-4 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-tomdar-orange-100"
            >505-505-166</span
          >
        </h1>
        <p
          class="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl"
        >
          Mamy przyjemność zaprezentować Państwu naszą ofertę na świadczenie usług pralniczych w&nbsp;zakresie prania wodnego i&nbsp;chemicznego.
        </p>
        <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
          <div class="rounded-md shadow">
            <a
              href="#contact"
              class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-tomdar-blue-200 hover:bg-tomdar-blue-100 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out"
            >
              Skontaktuj się z&nbsp;nami
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full"
    >
      <img
        class="absolute inset-0 w-full h-full object-cover"
        src="../assets/cover.jpg"
        alt=""
      />
    </div>
  </main>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>
