<template>
  <section id="about-us" class="relative bg-gray-50 py-16 sm:py-24">
    <div
      class="lg:mx-auto lg:max-w-7xl lg:px-4 flex flex-col lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start"
    >
      <div class="relative order-last lg:order-first sm:py-16 lg:py-0">
        <div
          aria-hidden="true"
          class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
        >
          <svg
            class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
            width="404"
            height="392"
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="392"
              fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
            />
          </svg>
        </div>
        <div
          class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-10"
        >
          <div class="relative pt-12 pb-10">
            <div
              class="container mx-auto space-y-4 lg:space-y-0 lg:gap-2 lg:grid lg:grid-cols-2"
            >
              <div class="w-full h-full">
                <img
                  class="object-contain rounded shadow"
                  src="../assets/machines_gray.jpg"
                  alt="machines_gray"
                />
              </div>
              <div class="w-full h-full">
                <img
                  class="object-contain rounded shadow"
                  src="../assets/machines_yellow.jpg"
                  alt="machines_yellow"
                />
              </div>
              <div class="w-full h-full">
                <img
                  class="object-contain rounded shadow"
                  src="../assets/machines_blue.jpg"
                  alt="machines_blue"
                />
              </div>
              <div class="w-full h-full">
                <img
                  class="object-contain rounded shadow"
                  src="../assets/truck.jpeg"
                  alt="truck"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
        <div class="pt-12 sm:pt-16 lg:pt-20">
          <h2
            class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
          >
            O&nbsp;nas
          </h2>
          <div class="mt-6 text-gray-500 space-y-6">
            <p class="text-base font-semibold">
              Pralnia&nbsp;TOMDAR - istniejemy na rynku pralniczym od 1996 roku.
              <br />
              Firma mieści się w&nbsp;Warszawie przy ulicy Strażackiej 104.
            </p>
            <p class="text-base leading-7">
              Dysponujemy własną bazą transportową. W&nbsp;skład tej bazy
              wchodzą samochody ciężarowe oraz dostawcze wyposażone w&nbsp;windy
              załadowcze.
            </p>
            <p class="text-base leading-7">
              Posiadamy maszyny i&nbsp;urządzenia pralnicze znanych na światowym
              rynku firm: ELEKTROLUX, KANNEGISSER i&nbsp;LAPAUW
            </p>
            <p class="text-base leading-7">
              Współpracujemy z&nbsp;technologami firm produkujących środki
              piorące – CLOVIN, ECOLAB, CHRISTENSEN. Wszystkie stosowane przez
              nas środki piorące posiadają wymagane atesty oraz dopuszczenia do
              użytkowania.
            </p>
            <p class="text-base leading-7">
              Posiadamy pralnie chemiczną, gdzie czyścimy odzież gości
              hotelowych i&nbsp;personelu hotelowego.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped></style>
