<template>
  <div id="contact" class="relative bg-gray-50">
    <div class="absolute inset-0">
      <div class="absolute inset-y-0 left-0 w-1/2" />
    </div>
    <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-4">
      <div
        class="sm:flex sm:justify-center py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12"
      >
        <div
          class="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8"
        >
          <div>
            <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              Kontakt
            </h2>
            <dl class="mt-8 text-base text-gray-500">
              <div>
                <dt class="sr-only">Postal address</dt>
                <dd class="flex">
                  <OfficeBuildingIcon
                    class="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="ml-2">ul. Strażacka 104</span>
                </dd>
                <dd class="ml-9">04-455 Warszawa</dd>
              </div>
              <div class="mt-6">
                <dt class="sr-only">Phone number</dt>
                <dd class="flex">
                  <PhoneIcon
                    class="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="ml-2"> 505-505-166 </span>
                </dd>
                <dd class="ml-9">505-505-022</dd>
              </div>
              <div class="mt-3">
                <dt class="sr-only">Email</dt>
                <dd class="flex">
                  <MailIcon
                    class="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <a href="mailto:tomdar@onet.com.pl" class="ml-2"
                    >tomdar@onet.com.pl</a
                  >
                </dd>
              </div>
            </dl>
          </div>
          <div class="mt-12 sm:mt-16 md:mt-0">
            <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              Dane do faktury
            </h2>
            <div class="mt-8">
              <div class="flex">
                <div class="flex-shrink-0">
                  <BriefcaseIcon
                    class="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-2 text-base text-gray-500">
                  <p><span class="font-semibold">TOMDAR </span> Spółka jawna</p>
                  <p>NIP: 113-25-68-399</p>
                  <p class="mt-1">REGON: 140197149</p>
                  <p class="mt-1">KRS: 0000317675</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex mx-auto py-16 px-4 sm:px-6 lg:col-span-2 lg:py-24 lg:px-8 xl:pl-12"
      >
        <div class="max-w-lg mx-auto lg:max-w-none">
          <iframe
            width="350"
            height="300"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            id="gmap_canvas"
            src="https://maps.google.com/maps?width=350&amp;height=300&amp;hl=en&amp;q=Stra%C5%BCacka%20104%20Warszawa+()&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MailIcon,
  PhoneIcon,
  BriefcaseIcon,
  OfficeBuildingIcon,
} from "@heroicons/vue/outline";

export default {
  name: "Contact",

  components: {
    MailIcon,
    PhoneIcon,
    BriefcaseIcon,
    OfficeBuildingIcon,
  },
};
</script>

<style scoped></style>
